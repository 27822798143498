<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col>
          <h1>Archived Opportunities</h1>
        </v-col>
        <v-col cols="6">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: 'module-opportunities' }">
              <v-icon left>mdi-target</v-icon>Active Opportunities
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="archived"
            :items-per-page="itemsPerPage"
            no-data-text="There are no archived opportunities"
          >
            <template
              v-slot:item.project_amount="{item}"
            >£{{Number.parseFloat(item.project_amount)}}</template>
            <template
              v-slot:item.monthly_amount="{item}"
            >£{{Number.parseFloat(item.monthly_amount)}}</template>
            <template v-slot:item.yearly_amount="{item}">£{{Number.parseFloat(item.yearly_amount)}}</template>
            <template v-slot:item.status="{item}">
              <v-chip
                small
                label
                :color="getStatusColor(item.status)"
              >{{getStatusLabel(item.status)}}</v-chip>
            </template>
            <template v-slot:item.start_date="{item}">
              <v-chip small label>{{getDate(item.start_date)}}</v-chip>
            </template>
            <template v-slot:item.actions="{item}">
              <v-tooltip left>
                <template v-slot:activator="{on}">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    class="action-button"
                    @click="activateOpportunityDialog=true;activateOpportunityData=item"
                  >
                    <v-icon small>mdi-account-check</v-icon>
                  </v-btn>
                </template>
                <span>Restore</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="activateOpportunityDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Opportunity</v-card-title>
        <v-card-text>Are you sure you want to restore this opportunity?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="activateOpportunityDialog=false">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="activateOpportunityLoading"
            @click="reactivateOpportunity()"
          >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableHeaders: [
        { text: "Customer", value: "customer.name" },
        { text: "Description", value: "description" },
        { text: "Project", value: "project_amount" },
        { text: "Monthly", value: "monthly_amount" },
        { text: "Yearly", value: "yearly_amount" },
        { text: "Status", value: "status" },
        { text: "Start Date", value: "start_date" },
        { text: "Actions", value: "actions" }
      ],
      itemsPerPage: -1,
      opportunityStatuses: [
        { value: "active", label: "Active", color: "blue white--text" },
        { value: "client-yes", label: "Client Yes", color: "default" },
        { value: "won", label: "Won", color: "success" },
        { value: "on-hold", label: "On-Hold", color: "warning" }
      ],
      activateOpportunityDialog: false,
      activateOpportunityLoading: false,
      activateOpportunityData: null
    };
  },

  computed: {
    archived() {
      return this.$store.state.opportunities["archived"];
    },
    customers() {
      return this.$store.state.customers["customers"];
    }
  },

  methods: {
    reactivateOpportunity: function() {
      const appId = this.$route.params.id;
      const id = this.activateOpportunityData.id;

      this.activateOpportunityLoading = true;

      this.$store
        .dispatch("opportunities/reactivateOpportunity", {
          appId,
          id
        })
        .then(() => {
          this.activateOpportunityData = null;
          this.activateOpportunityLoading = false;
          this.activateOpportunityDialog = false;
        });
    },
    getStatusLabel: function(value) {
      const status = this.opportunityStatuses.find(s => s.value === value);

      return status.label;
    },
    getStatusColor: function(value) {
      const status = this.opportunityStatuses.find(s => s.value === value);

      return status.color;
    },
    getDate: function(date) {
      const d = new Date(date);
      const m = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ];

      return m[d.getMonth()] + "/" + d.getFullYear();
    }
  }
};
</script>

<style>
.action-row {
  width: 136px;
}
.action-button {
  padding: 0px !important;
  min-width: 28px !important;
}
</style>
